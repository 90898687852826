import React from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { isAuthenticated } from "./services/Authentication/auth";


//Páginas
const loading = () => <div className="animated fadeIn pt-3 text-center">Carregando...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Authentication/Login'));
const PasswordRemember = React.lazy(() => import('./views/Pages/Authentication/PasswordRemember'));
const PasswordChange = React.lazy(() => import('./views/Pages/Authentication/PasswordChange'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <DefaultLayout {...props} />
      ) : (
          <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
        )
    }
  />
);

const Routes = () => (
  <HashRouter>
    <React.Suspense fallback={loading()}>
      <Switch>
        <Route exact path="/login" name="Página de Login" render={props => <Login {...props} />} />
        <Route path="/password-remember/:email?" name="Esqueci minha senha" render={props => <PasswordRemember {...props} />} />
        <Route path="/password-change/:email?" name="Trocar senha" render={props => <PasswordChange {...props} />} />
        <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
        <PrivateRoute path="/" name="Home" render={props => <DefaultLayout {...props} />} />
      </Switch>
    </React.Suspense>
  </HashRouter>
);

export default Routes;